import React from "react";
import { graphql, Link } from "gatsby"
import "../styles/index.css";
import SEO from "../components/SEO";

export default function Index({ data }) {
  const { edges: posts } = data.allMarkdownRemark
  return (
    <div className="max-w-4xl mx-auto my-8 px-2">
      <SEO title="Blog" />
      <header className="mb-4">
        <h1 className="text-xl">Raihan Ramadistra</h1>
        <h4>Welcome to my blog! I am currently writing about CTFs.</h4>
      </header>
      <section className="blog-posts">
        {posts
          .filter(post => post.node.frontmatter.title.length > 0)
          .map(({ node: post }) => {
            return (
              <Link to={post.frontmatter.path}>
                <article className="bg-white border-blue-500 border-solid border rounded p-6 mb-4" key={post.id}>
                  <h1>
                    <div>{post.frontmatter.title}</div>
                  </h1>
                  <small>{post.frontmatter.date} • {post.timeToRead} min read</small>
                </article>
              </Link>
            )
          })}
      </section>
    </div>
  )
}

export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          excerpt(pruneLength: 250)
          id
          timeToRead
          frontmatter {  
            title
            date(formatString: "MMMM DD, YYYY")
            path
            summary
          }
        }
      }
    }
  }
`
